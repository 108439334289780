<template>
    <DashboardContainer>
        <DeskingLog/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import DeskingLog from '../components/desking/DeskingLog'

export default {
    name: 'Desking',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    components: {
        DashboardContainer,
        DeskingLog
    }
}
</script>